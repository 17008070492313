import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import Media from "@components/media";
import Phrase from "@components/phrase";
import { useWindowSize } from "@lib/helpers";
import Icon from "@components/icon";
import BlockContent from "@components/block-content";
import {
  m,
  useViewportScroll,
  useTransform,
  useAnimation,
} from "framer-motion";
import { useRefScrollProgress } from "@lib/helpers";
import { useInView } from "react-intersection-observer";
const TextAndMedia = ({ data = {} }) => {
  const { style, sectionTitle, body, media, phrase, includeScaleAnimation } =
    data;

  const [hideText, setHideText] = useState(false);

  const { width: windowWidth } = useWindowSize();

  const componentRef = useRef(),
    { start, end } = useRefScrollProgress(componentRef);
  const { scrollY, scrollYProgress } = useViewportScroll();

  const scale = useTransform(
      scrollYProgress,
      [start, end],
      [1, windowWidth > 768 ? 1.81818182 : 1]
    ),
    opacity = useTransform(
      scrollYProgress,
      [start, end],
      [1, windowWidth > 768 ? 0 : 1]
    );

  useEffect(
    () =>
      opacity.onChange((latest) => {
        if (includeScaleAnimation) {
          if (latest < 1) {
            setHideText(true);
          } else {
            setHideText(false);
          }
        }
      }),
    [opacity, hideText, includeScaleAnimation, scrollYProgress]
  );

  const horizontalLineVariants = {
    visible: {
      opacity: 1,
      maxWidth: "600px",
      transition: { duration: 2, ease: "easeInOut" },
    },
    hidden: { opacity: 0, maxWidth: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  if (style == "atAGlance") {
    return (
      <section className={cx("text-and-media")}>
        <div
          className={cx(
            "grid",
            "grid-cols-4",
            "md:grid-cols-20",
            "place-content-center",
            "text-center",
            "px-gutter",
            "py-gutter",
            "md:py-[60px]",
            "site-container-xl"
          )}
        >
          <div className={cx("col-span-4", "md:col-span-18", "md:col-start-2")}>
            <Media media={media} layout="fill" aspect="video" />
          </div>
          <div
            className={cx(
              "col-span-4",
              "md:col-span-18",
              "md:col-start-2",
              "p-5",
              "bg-white",
              "rounded-b-[3px]",
              "border-t-yellow",
              "border-t-[5px]",
              "flex",
              "flex-col",
              "md:flex-row"
            )}
          >
            <div className={cx("hidden", "sm:block", "w-full")}>
              <Phrase
                phrase={phrase}
                dividerMaxWidth="5%"
                typeStyle={
                  "text-subheader-2 whitespace-nowrap uppercase font-sansRegular"
                }
              />
            </div>
            <div className={cx("flex", "sm:hidden")}>
              <Phrase
                phrase={phrase}
                vertical={true}
                hideLast={true}
                dividerMaxWidth="50px"
                typeStyle={"text-subheader-2 uppercase font-sansRegular"}
                wordStyle={"inline-block mt-[4px] mb-[4px]"}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
  if (style == "fullWidth") {
    return (
      <section className={cx("text-and-media", "mt-[100px]")}>
        <div
          className={cx("grid", "grid-cols-1", "p-gutter", "site-container-xl")}
        >
          <div className={cx("col-span-1")}>
            <p className={cx("text-lg-2-mobile", "md:text-lg-2")}>
              {sectionTitle}
            </p>
          </div>
          <div className={cx("col-span-1")}>
            <Media media={media} layout="intrinsic" />
          </div>
        </div>
      </section>
    );
  }
  if (style == "fullWidthTextBelow") {
    return (
      <section className={cx("text-and-media")}>
        <div
          className={cx("grid", "grid-cols-1", "p-gutter", "site-container-xl")}
        >
          <div className={cx("col-span-1")}>
            <Media media={media} layout="fill" aspect="video" />
          </div>
        </div>
        <div
          className={cx(
            "grid",
            "grid-cols-4",
            "sm:grid-cols-20",
            "gap-gutter",
            "p-gutter",
            "site-container-xl"
          )}
        >
          <div className={cx("col-span-4", "sm:col-span-9")}>
            <div
              className={cx(
                "text-subheader-2",
                "uppercase",
                "flex",
                "flex-col",
                "sm:flex-row",
                "items-center",
                "mt-3"
              )}
            >
              <span
                className={cx(
                  "whitespace-nowrap",
                  "sm:mr-5",
                  "text-center",
                  "w-full",
                  "block",
                  "sm:w-auto",
                  "sm:text-left",
                  "font-sansRegular"
                )}
              >
                {sectionTitle}
              </span>
              <m.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={horizontalLineVariants}
                className={cx(
                  "divider",
                  "h-[120px]",
                  "w-[1px]",
                  "mt-2",
                  "sm:mt-0",
                  "sm:w-[1000px]",
                  "sm:h-[1px]",
                  "flex"
                )}
                style={{
                  backgroundColor: "#000000",
                }}
              ></m.div>
            </div>
          </div>
          <div
            className={cx(
              "col-span-4",
              "sm:col-span-11",
              "text-lg-2-mobile",
              "md:text-lg-2",
              "text-left"
            )}
          >
            <BlockContent blocks={body} />
          </div>
        </div>
      </section>
    );
  }
  if (includeScaleAnimation) {
    return (
      <section className={cx("text-and-media", "sm:h-[180vh]")}>
        <div className={cx("card", "m-2", "flex", "flex-column", "h-full")}>
          <div
            className={cx(
              "grid",
              "p-1",
              "grid-cols-1",
              "sm:grid-cols-20",

              "w-full",
              "rounded-[3px]",
              "sticky",
              "bottom-[56px]",
              "mt-auto",
              "site-container-xl",
              "transition-background",
              "duration-500",
              {
                "bg-white": !hideText,
                "bg-transparent": hideText,
              }
            )}
          >
            <m.div
              className={cx(
                "col-span-9",
                "flex",
                "flex-col",
                "p-2",
                "transition-opacity",
                "duration-500",
                {
                  "opacity-100": !hideText,
                  "opacity-0": hideText,
                }
              )}
            >
              <div
                className={cx(
                  "text-subheader",
                  "font-sansRegular",
                  "mb-10",
                  "md:mb-0",
                  "uppercase"
                )}
              >
                {sectionTitle}
              </div>
              <div
                className={cx(
                  "mt-auto",
                  "text-caption",
                  "font-sansRegular",
                  "max-w-[300px]",
                  "mb-2",
                  "md:mb-0"
                )}
              >
                <div className={cx("border-t", "w-[50px]", "mb-2")}></div>
                <BlockContent blocks={body} />
              </div>
            </m.div>
            <m.div
              className={cx("col-span-11", "origin-bottom-right")}
              ref={componentRef}
              style={{
                scale,
              }}
            >
              <Media media={media} layout="intrinsic" />
            </m.div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className={cx("text-and-media")} ref={componentRef}>
      <div
        className={cx("card", "m-2", {
          "sm:h-[100vh] flex flex-column": includeScaleAnimation,
        })}
      >
        <div
          className={cx(
            "grid",
            "p-1",
            "grid-cols-1",
            "sm:grid-cols-20",
            "bg-white",
            "w-full",
            "rounded-[3px]",
            "site-container-xl"
          )}
        >
          <div className={cx("col-span-9", "flex", "flex-col", "p-2")}>
            <div
              className={cx(
                "text-subheader",
                "font-sansRegular",
                "mb-10",
                "md:mb-0",
                "uppercase"
              )}
            >
              {sectionTitle}
            </div>

            <div
              className={cx(
                "mt-auto",
                "text-caption",
                "font-sansRegular",
                "max-w-[300px]",
                "mb-2",
                "md:mb-0"
              )}
            >
              <div className={cx("border-t", "w-[50px]", "mb-2")}></div>
              <BlockContent blocks={body} />
            </div>
          </div>
          <div className={cx("col-span-11")}>
            <Media media={media} layout="intrinsic" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextAndMedia;
